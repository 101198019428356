import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"


export default () => (
  <Layout>
    <SEO title="Hydrologický cyklus" />
    <h1>Hydrologický cyklus</h1>
    <p>Mezi z&aacute;kladn&iacute; principy existence hydrosf&eacute;ry patř&iacute; <strong>hydrologick&yacute; cyklus</strong>. Jde o nepřetržitou, přirozenou cirkulaci vody na Zemi, kterou poh&aacute;n&iacute; předev&scaron;&iacute;m slunečn&iacute; energie a gravitace. D&aacute; se vyj&aacute;dřit jako n&aacute;slednost změn skupenstv&iacute; vody, jej&iacute;ho stavu, m&iacute;st a procesů, kter&eacute; tyto změny spojuj&iacute;.</p>
    <hr />
    <h2>Členěn&iacute; hydrologick&eacute;ho cyklu</h2>
    <p>Nejzn&aacute;měj&scaron;&iacute; rozdělen&iacute; typů hydrologick&eacute;ho cyklu je n&aacute;sleduj&iacute;c&iacute;:</p>
    <ul>
    <li><strong>velk&yacute; oběh vody</strong> &ndash; prob&iacute;h&aacute; mezi oce&aacute;nem, atmosf&eacute;rou i pevninou</li>
    <li><strong>mal&yacute; oběh vody</strong>
    <ul>
    <li>buď prob&iacute;h&aacute; <strong>pouze mezi oce&aacute;nem a atmosf&eacute;rou</strong> (voda se vypař&iacute; z oce&aacute;nu a n&aacute;sledně do něj zase napr&scaron;&iacute;),</li>
    <li>nebo doch&aacute;z&iacute; k v&yacute;měně <strong>pouze mezi pevninou a atmosf&eacute;rou</strong>, což je aplikovateln&eacute; na bezodtok&eacute; oblasti (v&yacute;par vody z pevniny, sr&aacute;žky v podobě de&scaron;tě opět padaj&iacute; na pevninu)</li>
    </ul>
    </li>
    </ul>
    <p>V&yacute;&scaron;e zm&iacute;něn&eacute; členěn&iacute; spad&aacute; do tzv. <strong>atmosferick&eacute;ho cyklu</strong>, kter&yacute; je pojmenov&aacute;n dle cesty transportu vody v&nbsp;klimatick&eacute;m koloběhu. Koloběh vodn&iacute; p&aacute;ry v&nbsp;atmosf&eacute;ře trv&aacute; přibližně 10 dn&iacute;. D&aacute;le je&scaron;tě rozli&scaron;ujeme <strong>cyklus povrchov&eacute;ho</strong> (čas koloběhu asi 12 dn&iacute;) a <strong>podzemn&iacute;ho odtoku</strong> (průměrně 5&nbsp;000 let, v&nbsp;aktivn&iacute; z&oacute;ně 330 let).</p>
    <hr />
    <h2>Rozložen&iacute; z&aacute;sob vody na Zemi</h2>
    <p>Z&nbsp;celkov&eacute; plochy Země 510 mil. km2 zab&iacute;raj&iacute; oce&aacute;ny a moře 361 mil. km3, tedy zhruba 70,8 % povrchu. Voda a sou&scaron; jsou rozloženy nerovnoměrně, což se v&yacute;razně projevuje v&nbsp;oběhu vody, diferenciaci klimatu, ve vodn&iacute; bilanci a dal&scaron;&iacute;ch.</p>
    <p>Jde o pouh&eacute; odhady a celkov&yacute;ch z&aacute;sob&aacute;ch vody si lze naj&iacute;t mnoho na jin&yacute;ch str&aacute;nk&aacute;ch, zmiňme v&scaron;ak pro zaj&iacute;mavost, že ve světov&eacute;m oce&aacute;nu se koncentruje přes 97 % v&scaron;ech z&aacute;sob vody a že zhruba 79 % sladk&eacute; pevninov&eacute; vody se v&aacute;že na ledovce a jen 0,015 % se pak nach&aacute;z&iacute; v&nbsp;korytech řek.</p>
    <p>Odhad celkov&eacute;ho v&yacute;paru, kter&yacute; je poh&aacute;něn slunečn&iacute;m z&aacute;řen&iacute;m, čin&iacute; 577 tis&iacute;c km3. 505 tis&iacute;c km3 pak připad&aacute; na oce&aacute;ny, z&nbsp;nichž vět&scaron;ina vypadne pr&aacute;vě zase na oce&aacute;n, a pouze 72 tis&iacute;c km3 na pevninu. Z&nbsp;prostoru nad světov&yacute;m oce&aacute;nem se přenese na pevninu asi 47 tis. km3 vody, což čin&iacute; 8 % v&yacute;paru z&nbsp;oce&aacute;nu. Skutečn&aacute; v&yacute;měna vlhkosti mezi oce&aacute;ny a pevninou je v&scaron;ak velmi složit&aacute;, č&aacute;st vlhkosti se totiž jen doprav&iacute; nad pevninu, n&aacute;sledně zpět nad oce&aacute;n a nespadne tak na povrch pevniny &ndash; pak mluv&iacute;me o tranzitn&iacute; vl&aacute;ze. Pod&iacute;l t&eacute;to tranzitn&iacute; vl&aacute;hy nen&iacute; nad každ&yacute;m kontinentem stejn&yacute;, nad Austr&aacute;li&iacute; čin&iacute; asi 76 %, kdežto nad Asi&iacute; jen 20 %.</p>
    <hr />
    <h2>Odtok z&nbsp;&uacute;moř&iacute;</h2>
    <p><strong>&Uacute;moř&iacute; </strong>je č&aacute;st pevniny, ze kter&eacute; v&scaron;echna povrchov&aacute; voda teče do jednoho moře nebo oce&aacute;nu<strong>. </strong>Jednotliv&aacute; &uacute;moř&iacute; jsou od sebe oddělena rozvodnicemi či tzv. hlavn&iacute;mi rozvodn&iacute;mi, v&nbsp;př&iacute;padě &uacute;moř&iacute; oce&aacute;nů hovoř&iacute;me o kontinent&aacute;ln&iacute;ch rozvodnic&iacute;ch. Bezodtok&eacute; oblasti</p>
    <p>Na hydrologick&eacute;m cyklu se odtok z nich pod&iacute;l&iacute; 47&nbsp;tis. km3 vody (tedy tolik, co spadne na pevninu ve formě sr&aacute;žek!). Pokud bychom takov&eacute; množstv&iacute; vody rovnoměrně rozlili na plochu světov&eacute;ho oce&aacute;nu, v&yacute;sledn&yacute; vodn&iacute; pokryv by měl v&yacute;&scaron;ku 130 mm.</p>
    <p>V&nbsp;celoplanet&aacute;rn&iacute;m odtoku jsou v &uacute;moř&iacute;ch velk&eacute; rozd&iacute;ly, kter&eacute; jsou d&aacute;ny prostorovou variabilitou, mj. d&aacute;ny vydatnost&iacute; zdrojů odtoků &ndash; velk&yacute;ch řek či klimatick&yacute;mi podm&iacute;nkami.</p>
    <p>Pokud bychom odtok Severn&iacute;ho ledov&eacute;ho oce&aacute;nu rozprostřeli na jeho plochu, dostaneme v&yacute;&scaron;ku vodn&iacute;ho sloupce dosahuj&iacute;c&iacute; již 355 mm. Atlantik s&nbsp;226 mm je t&eacute;ž v&yacute;razně nadprůměrn&yacute;. Naopak podprůměrn&yacute; je Tich&yacute; oce&aacute;n (83 mm) a Indick&yacute; oce&aacute;n (80 mm).</p>
    <p>Je&scaron;tě vět&scaron;&iacute; rozd&iacute;ly bychom pozorovali, pokud bychom sledovali &uacute;moř&iacute; jednotliv&yacute;ch moř&iacute; &ndash; např. u Karsk&eacute;ho moře by v&yacute;&scaron;ka sloupce dosahovala 1530 mm.</p>
    <hr />
    <h2>Rovnice celoplanet&aacute;rn&iacute; hydrologick&eacute; bilance</h2>
    <p>Oběh vody na Zemi lze vyj&aacute;dřit jednoduch&yacute;mi rovnicemi, kter&eacute; jsou matematick&yacute;m modelem jeho bilance. Prvky je možn&eacute; vyj&aacute;dřit těmito symboly:</p>
    <div className="od-sm">
    <p>Eo &hellip; v&yacute;par ze světov&eacute;ho oce&aacute;nu</p>
    <p>Ep &hellip; v&yacute;par z&nbsp;pevniny</p>
    <p>So &hellip; sr&aacute;žky na hladinu oce&aacute;nu</p>
    <p>Sp &hellip; sr&aacute;žky na povrch pevniny</p>
    <p>O &hellip; odtok z&nbsp;pevniny</p>
    </div>
    <p>Z&nbsp;podstaty oběhu vody je možn&eacute; zapsat bilančn&iacute; rovnice v&nbsp;n&aacute;sleduj&iacute;c&iacute;ch tvarech:</p>
    <div className="od-sm">
    <p>Eo = So + O</p>
    <p>Ep = Sp &ndash; O</p>
    <p>So + Sp = Eo + Ep</p>
    </div>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Úvod do hydrologie a hydrogeografie</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/uvod-do-studia/"><button className="inv">&larr; Úvod do studia hydrologie</button></Link>
    <Link to="/hydrologie/protipovodnova-opatreni/"><button className="inv">Protipovodňová opatření &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
